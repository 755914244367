.status-panel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 2.5em;
}

.status-panel-container > svg{
    width: 1.5em;
    color: gray;
    height: 1.5em;
}


.status-panel-container > svg:nth-child(2){
    color: white;
    position: relative;
    left: -1.5em;
}

.status-panel-container .sync-defect-btn{
    color: #ee7f00;
    width: 26px;
}
.status-panel-container .reduced-defect-list-btn {
    color: #ee7f00;
    width: 26px;
}

.status-panel-container .defect-syncing{
    animation: spin 2000ms infinite linear;
}

.status-panel-container > img {
    position: relative;
    left: -5.1em;
}

.connect-status-container {
    position: relative;
    left: -5em;
    height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.connect-status-container span {
    font-size: 15px;
    font-family: sans-serif;
}

.connect-status-container svg:first-child {
    width: 11px;
    height: 11px;
    padding-top: 3px;
    margin-right: 3px;
}

.connect-status-container svg:nth-child(2) {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    animation: spin 2000ms infinite linear;
}


.connect-status-container > div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.connect-status-container > div:first-child {
    margin-bottom: -3px;
}

.connect-status-container > div:nth-child(2) {
    margin-top: -3px;
}

.options-menu-container .options-menu-button {
    width: 45px;
    height: 45px;
}

.options-menu-button svg {
    width: 43px;
    height: 43px;
}

.clickable {
    cursor: pointer;
}

@media screen and (max-width: 599.95px){
    .options-menu-container .options-menu-button {
        width: 37px;
        height: 37px;
    }

    .options-menu-button svg {
        width: 35px;
        height: 35px;
    }

    .connect-status-container span {
        font-size: 13px;
    }

    .connect-status-container svg:nth-child(2) {
        width: 15px;
        height: 15px;
        margin-left: 3px;
    }
}

@media (max-width: 900px) and (min-width:600px){

}

@media (min-width: 900px) {

}