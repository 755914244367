
/*--------------delete after immigration completes------------------*/
.ui-mobile .whole-settings-container label {
    display: flex;
    margin: 0;
}

.ui-mobile .settings-checkbox-container label {
    margin: 0 0 .4rem;
}

#image-page-header .enableMarkerPlacement {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    margin-left: -11px;
    margin-right: 16px;
}

.fileTrigger.fileTriggerToFiles.fileTriggerImage, .fileTriggerToPlan, .photoTrigger, .feTrigger, .importTrigger, .extannotationTrigger, .moreDefectAttachmentOptions, .document-frame-img, .photo-frame-img, .copyPasteTrigger {
    background-size: contain;
}

/* png images have to be smaller then 10kB for them to be converted to base64 by webpack (this is important if the images should be available in offline mode) */
.fileTriggerToFiles {
    background-image: url("../images/file.png");
}

.fileTriggerToFiles[inverted="true"] {
    background-image: url("../images/file_inverted(stripped).png");
}

.fileTriggerToPlan {
    background-image: url("../images/file_inverted_rocket(stripped).png");
}

.photoTrigger {
    background-image: url("../images/photo.png");
}

.feTrigger {
    background-image: url("../images/icons/icon-baufoto-2020.png");
}

.importTrigger {
    background-image: url("../images/import.png");
}

.copyPasteTrigger {
    background-image: url("../images/clipboard.png");
}

.extannotationTrigger {

    background-image: url("../images/hoermann.png");
}

.moreDefectAttachmentOptions {

    background-image: url("../images/dots-grey-no-bg.png");
}

.document-frame-img, .photo-frame-img {
    background-image: url("../images/icons-png/delete-black.png");
}

.toast-btn {
    position: fixed;
    width: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
}

.copiable {
    cursor: pointer;
}

.an-request-preview div {
    width: 100%;
}

.whole-settings-container button {
    background-color: #666666;
}

.whole-settings-container button:hover {
    background-color: #111;
}

@media screen and (max-width: 599.95px){

}
/*--------------delete after immigration completes------------------*/


.login-container {
    width: 50vw;
}

.login-container .MuiGrid-item:first-child > img {
    width: 20%;
    border-radius: 1em;
}

.login-container .MuiGrid-item:nth-child(2) > img {
    width: 50%;
}

.login-container span {
    margin-bottom: -2em;
    margin-top: 2em;
    color: red;
}

.login-container .MuiGrid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
}
.login-container .MuiFormControl-root{
    width: 100%;
    background-color: white;
    border-radius: 1em;
}
.login-container label{
    color: darkgray;
}

.experienceId-input {
    margin-top: 2em;
}

.login-container .MuiGrid-item:nth-last-child(2) {
    justify-content: left;
}

.login-container .MuiGrid-item:last-child {
    justify-content: right;
}


#saveMenuDropdown > label {
    color: white;
    top: -9px;
}

#saveMenuDropdown > label.Mui-focused {
    display: none;
}




button.previous-defect, button.next-defect {
    position: fixed;
    bottom: 0px;
    background-color: white;
    color: black;
    font-size: .8rem;
    z-index: 11;
}

.previous-defect {
    left: 24px;
}

.next-defect {
    right: 24px;
}

.MuiBox-root > .enableMarkerPlacement {
    margin: 0;
}

.enableMarkerPlacement > span:nth-child(2) {
    color: #666666;
}

.MuiTypography-root.MuiTypography-h6.defect-type-title {
    color: #666666;
    cursor: pointer;
}

.MuiButtonBase-root.MuiButton-root.defect-type-btn {
    line-height: 1;
    border-radius: 0;
    min-width: 0;
    padding: 1px;
    font-size: .7em;
    color: #666666;
    background-color: transparent;
    overflow: hidden;
}
.MuiButtonBase-root.MuiIconButton-root.defect-favourite-btn,  .MuiButtonBase-root.MuiIconButton-root.defects-only-favourite{
    color: #ee7f00;
    padding: 0;
}
.MuiButtonBase-root.MuiIconButton-root.defect-favourite-btn {
    margin-top: 4px;
}
.MuiButtonBase-root.MuiIconButton-root.defect-favourite-btn,  .MuiButtonBase-root.MuiIconButton-root.defects-only-favourite{
    margin-right: 4px;
}
.MuiTypography-root.MuiTypography-h6.defect-title {
    margin-left: 8px;
    color: #333333;
    margin-right: 4px;
}

.whole-settings-container {
    padding: 1.5em 0;
}

.whole-settings-container button,
.whole-settings-container .MuiFormControl-root,
.whole-settings-container .MuiButtonGroup-root
{
    width: 100%;
}


.settings-checkbox-container label {
    background-color: #eeeeee;
}

.settings-switch {
    border-radius: 1rem;
}

.sigCanvas {
    background-color: #EEEEEE;
    border-radius: 4px;
}
.MuiButtonGroup-root button {
    overflow: hidden;
}

.whole-settings-container .MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type) {
    border-color: white;
}

.whole-settings-container .MuiSwitch-root>span.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked{
    color: #111;
}

.whole-settings-container .MuiSwitch-root>.MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
    background-color: #111;
}

#root>.MuiBox-root>.MuiGrid-root>div {
    width: 100%;
}

@media screen and (max-width: 599.95px){
    html {
        font-size: 11px !important;
    }
    .login-container {
        width: 80vw;
    }
    button.previous-defect, button.next-defect {
        bottom: 4px;

    }
    button.previous-defect {
        left: 16px;
    }
    button.next-defect {
        right: 16px ;
    }
}


@media (max-width: 900px) and (min-width:600px){

}

@media (min-width: 900px) {

}